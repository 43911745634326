import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

import Hero from "../images/404--image@2x.jpg"

const FourOhFour = () => (
  <Layout>
    <SEO title="Page Not Found" lang="en" meta={[]} />

    <div
      id="innerHero"
      className="row"
      style={{
        background: `url(${Hero}) no-repeat center bottom fixed`,
        backgroundSize: "cover",
        alignItems: "center",
        display: "flex",
        marginBottom: 25,
        paddingBottom: 50,
      }}
    >
      <div
        className="col-12 col-sm-12 col-md-12 col-lg-12 text--center font--18 font--light container"
        style={{ width: "100%" }}
      >
        <br />
        <br />
        <br />
        <br />
        <br />

        <h1>
          <span className="font--150">{"404"}</span>
          <br />
          <strong className="font--46 hidden--xs hidden--sm">
            {"Oops! We couldn't Find What You're Looking For."}
          </strong>
        </h1>
        <br />

        <hr className="shorty" />

        <br />

        <p>
          {
            "We couldn't find the page you are trying to access. But don't worry, we're here to help."
          }
        </p>

        <p>
          {
            "If you are trying to apply to a position, it is possible it has already been filled."
          }
        </p>

        <br />

        <p>
          {
            "If you've gotten here in error, no worries, you can head back home or check out some of our job listings."
          }
        </p>

        <br />
        <br />

        {/* <Link to="/jobs" className="btn radius--lg bg--upendoPrimary font--light border--none font--14 no--wrap" style={{ width: 250, display: 'inline-block' }}>
          <strong>
            {'View Available Jobs '}
            <i className="fas fa-chevron-right" aria-hidden="true" />
          </strong>
        </Link> */}
        <span className="hidden--xs hidden--sm">
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span>
        <br className="hidden--md hidden--lg" />
        <br className="hidden--md hidden--lg" />
        <Link
          to="/"
          className="btn radius--lg border border--upendoPrimary font--light font--14 shadow--sm no--wrap"
          style={{ width: 250, display: "inline-block" }}
        >
          <strong>
            {"Go Home "}
            <i className="fas fa-chevron-right" aria-hidden="true" />
          </strong>
        </Link>

        <br />
        <br />
        <br />
      </div>
    </div>
  </Layout>
)

export default FourOhFour
